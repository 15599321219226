import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "./axios/instance";
import { IResponse } from "./axios/entities";
import { getEnv } from "@/utils/storage";
import { AuthorizationCodeRequest } from "@/models/AuthorizationCodeRequest";

export class IdentityAPI extends HttpClient {
  constructor(token?: string, private env = getEnv()) {
    super(env.VUE_APP_IDENTITY_URL, token);
  }

  public authorizationCode = async (
    req: AuthorizationCodeRequest
  ): Promise<IResponse> => {
    const params = new URLSearchParams();
    params.append("grant_type", req.grantType);
    params.append("code", req.code);
    params.append("client_id", req.clientId);
    params.append("redirect_uri", req.redirectUrl);
    params.append("code_verifier", req.codeVerifier);

    const resp: IResponse = await this.instance
      .post("/connect/token", params, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
      .catch(catchAxiosError);
    return resp;
  };
}
